import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { LanguageService } from '@services/internal/language.service';
import { TransfersService } from '@services/transfers.service';
import { PageableModel, TransferModel } from '@shared/models';
import { TransferPageableParams } from '@shared/models/build-models';
import { TIcons } from '@shared/type/icons.type';
import { FilterUniqueArray } from '@shared/utils/arrays/filter-unique-array';
import { DisplayName } from '@shared/utils/form-elements/display-with-autocomplete';
import { BehaviorSubject, finalize, take, tap } from 'rxjs';

@Component({
  selector: 'app-transfer-autocomplete',
  templateUrl: './transfer-autocomplete.component.html',
  styleUrls: ['./transfer-autocomplete.component.scss'],
  standalone: false
})
export class TransferAutocompleteComponent {
  @Input() filterView: boolean = false;
  @Input() showClearIcon: boolean = false;
  @Input() required: boolean = false;
  @Input() label: string = LanguageService.instant('transfers.listTitle');
  @Input() transfer: TransferModel = new TransferModel();
  @Input() searchParams: Partial<TransferPageableParams> = new TransferPageableParams({});
  @Input() clearAfterSelection: boolean = false;
  @Input() selectedItems: TransferModel[] = [];

  @Output() selectedOptionEmitter = new EventEmitter<TransferModel>();
  @Output() clearInputEmitter = new EventEmitter<void>();

  private transfersService = inject(TransfersService);

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  searchedData$: BehaviorSubject<TransferModel[]> = new BehaviorSubject<TransferModel[]>([]);
  forceBlurEvent: boolean = false;

  readonly displayFn = DisplayName;
  readonly defaultSearchParams: TransferPageableParams = new TransferPageableParams({ size: 20 });

  selectOption(data: TransferModel): void {
    this.selectedOptionEmitter.emit(data);

    if (this.clearAfterSelection) {
      this.transfer = new TransferModel();
    }
  }

  doIconAction(icon: TIcons): void {
    if (icon === 'close') {
      this.clearInputEmitter.emit();
      this.searchTransfers('', true);
    }
  }

  searchTransfers(value: string, skipSelectSingleOption: boolean = false): void {
    this.initialLoading$.next(false);
    this.forceBlurEvent = false;

    if (this.loading$.value) {
      return;
    }

    this.loading$.next(true);
    this.searchedData$.next([]);

    const queryParams: TransferPageableParams = { ...this.defaultSearchParams, ...this.searchParams };

    if (value) {
      queryParams.search = value;
    }

    this.transfersService
      .getPageable(queryParams)
      .pipe(
        take(1),
        tap((data: PageableModel<TransferModel>) => {
          this.searchedData$.next(this.selectedItems.length ? FilterUniqueArray(data.content, this.selectedItems) : data.content);

          if (skipSelectSingleOption && this.searchedData$.value.length === 1) {
            this.forceBlurEvent = true;
            return;
          }

          if (!value && this.searchedData$.value.length === 1 && this.required) {
            this.selectOption(this.searchedData$.value[0]);
            this.forceBlurEvent = true;
          } else {
            this.forceBlurEvent = false;
          }
        }),
        finalize(() => this.loading$.next(false))
      )
      .subscribe();
  }
}
