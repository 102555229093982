import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { DisplayRegionName } from '@shared/utils/form-elements/display-with-autocomplete';
import { IFilter } from '@shared/interfaces';
import { RegionModel, UserModel } from '@shared/models';
import { LanguageService } from '@services/internal/language.service';
import { RegionsService } from '@services/regions.service';
import { RegionsPageableParams } from '@shared/models/build-models';
import { FilterUniqueArray } from '@shared/utils/arrays/filter-unique-array';
import { TIcons } from '@shared/type/icons.type';
import { DefaultAutoCompleteSize } from '@constants';

@Component({
  selector: 'app-region-autocomplete',
  templateUrl: './region-autocomplete.component.html',
  styleUrls: ['./region-autocomplete.component.scss'],
  standalone: false
})
export class RegionAutocompleteComponent {
  @Input() multiple: boolean = false;
  @Input() selectedItems: RegionModel[] = [];
  @Input() removable: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() invalid: boolean = false;
  @Input() region: RegionModel = new RegionModel();
  @Input() label: string = LanguageService.instant('shared.labels.region');
  @Input() hasRegionalManager: boolean = null;
  @Input() showClearButton: boolean = false;
  @Input() showLinkButton: boolean = false;
  @Input() regionalManager: UserModel = new UserModel();

  @Output() removeChipEmitter = new EventEmitter<string>();
  @Output() selectedOptionEmitter = new EventEmitter<RegionModel>();
  @Output() clearEmitter = new EventEmitter<void>();

  private regionsService = inject(RegionsService);

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  searchedData: RegionModel[] = [];
  forceBlurEvent: boolean = false;

  readonly displayFn = DisplayRegionName;

  doIconAction(icon: TIcons): void {
    if (icon === 'close') {
      this.region = new RegionModel();
      this.clearEmitter.emit();
      this.searchRegions('', true);
    }
  }

  searchRegions(value: string, skipSelectSingleOption: boolean = false, page: number = 0): void {
    this.initialLoading$.next(false);
    this.forceBlurEvent = false;

    if (this.loading$.value) {
      return;
    }

    this.loading$.next(true);
    this.searchedData = [];

    const params: IFilter = {
      search: value,
      size: DefaultAutoCompleteSize,
      state: 'ACTIVE',
      page,
      sort: 'name,asc'
    };

    const queryParams: RegionsPageableParams = new RegionsPageableParams(params);

    if (this.hasRegionalManager !== null) {
      queryParams.hasRegionalManager = this.hasRegionalManager;
    }

    if (this.regionalManager?.id) {
      queryParams.regionalManagerId = this.regionalManager.id;
    }

    this.regionsService
      .getPageable(queryParams)
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(data => {
        if (this.multiple) {
          this.searchedData = FilterUniqueArray(data.content, this.selectedItems);
        } else {
          this.searchedData = data.content;
        }

        if (skipSelectSingleOption && this.searchedData.length === 1) {
          this.forceBlurEvent = true;
          return;
        }

        if (!value && this.searchedData.length === 1 && this.required) {
          this.selectOption(this.searchedData[0]);
          this.forceBlurEvent = true;
        } else {
          this.forceBlurEvent = false;
        }
        //CC-5432 Load next page if all items selected
        if (this.searchedData?.length === 0 && data?.totalElements > this.selectedItems?.length && page < data.totalPages) {
          setTimeout(() => this.searchRegions(value, skipSelectSingleOption, page + 1));
        }
      });
  }

  removeChip(id: string): void {
    this.removeChipEmitter.emit(id);
  }

  selectOption(region: RegionModel): void {
    this.selectedOptionEmitter.emit(region);
    if (this.multiple) {
      //Hide selected item from search results
      this.searchedData = this.searchedData.filter(c => c.id !== region.id);
    }
  }
}
