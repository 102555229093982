import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { PriceSheetModel } from '@shared/models';
import { DisplayName } from '@shared/utils/form-elements/display-with-autocomplete';
import { IFilter } from '@shared/interfaces';
import { PriceSheetPageableParams } from '@shared/models/build-models';
import { TIcons } from '@shared/type/icons.type';
import { PermissionService } from '@services/internal/permission.service';
import { PriceSheetsService } from '@services/price-sheets.service';
import { DefaultAutoCompleteSize } from '@constants';

@Component({
  selector: 'app-price-sheet-autocomplete',
  templateUrl: './price-sheet-autocomplete.component.html',
  styleUrls: ['./price-sheet-autocomplete.component.scss'],
  standalone: false
})
export class PriceSheetAutocompleteComponent {
  @Input() priceSheet: PriceSheetModel = new PriceSheetModel();
  @Input() showOpenInNewIcon: boolean = false;
  @Input() showClearIcon: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;

  @Output() selectedOptionEmitter = new EventEmitter<PriceSheetModel>();
  @Output() clearInputEmitter = new EventEmitter<void>();

  permissionService = inject(PermissionService);
  private priceSheetsService = inject(PriceSheetsService);

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  searchedData: PriceSheetModel[] = [];
  forceBlurEvent: boolean = false;

  readonly displayFn = DisplayName;

  searchPriceSheet(value: string, skipSelectSingleOption: boolean = false): void {
    this.initialLoading$.next(false);
    this.forceBlurEvent = false;

    if (this.loading$.value) {
      return;
    }

    this.loading$.next(true);
    this.searchedData = [];

    const params: IFilter = {
      search: value,
      size: DefaultAutoCompleteSize,
      state: 'ACTIVE',
      sort: 'name,asc'
    };

    const queryParams: PriceSheetPageableParams = new PriceSheetPageableParams(params);

    this.priceSheetsService
      .getPageable(queryParams)
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(data => {
        this.searchedData = data.content;

        if (skipSelectSingleOption && this.searchedData.length === 1) {
          this.forceBlurEvent = true;
          return;
        }

        if (!value && this.searchedData.length === 1 && this.required) {
          this.selectOption(this.searchedData[0]);
          this.forceBlurEvent = true;
        } else {
          this.forceBlurEvent = false;
        }
      });
  }

  selectOption(priceSheet: PriceSheetModel): void {
    this.selectedOptionEmitter.emit(priceSheet);
  }

  doIconAction(icon: TIcons): void {
    if (icon === 'close') {
      this.clearInputEmitter.emit();
      this.searchPriceSheet('', true);
    }
  }
}
