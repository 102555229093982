import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PermissionService } from '@services/internal/permission.service';
import { PhysiciansService } from '@services/physicians.service';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { PhysicianModel, ProcedureModel, UserModel } from '@shared/models';
import { DisplayFullName } from '@shared/utils/form-elements/display-with-autocomplete';
import { IFilter } from '@shared/interfaces';
import { PhysiciansPageableParams } from '@shared/models/build-models';
import { FilterUniqueArray } from '@shared/utils/arrays/filter-unique-array';
import { TIcons } from '@shared/type/icons.type';
import { CreateMediumEntityConfig, DefaultAutoCompleteSize } from '@constants';
import { CreatePhysicianComponent } from 'src/app/features/directory/physicians/partials/modals/create-physician/create-physician.component';
import { UsersService } from '@services/users.service';
import { TranslationsKeys } from '@shared/type/i18n.type';

@Component({
  selector: 'app-physician-autocomplete',
  templateUrl: './physician-autocomplete.component.html',
  styleUrls: ['./physician-autocomplete.component.scss'],
  standalone: false
})
export class PhysicianAutocompleteComponent {
  @Input() physician: PhysicianModel = new PhysicianModel();
  @Input() filterView: boolean = false;
  @Input() multiple: boolean = false;
  @Input() showAddNewButton: boolean = false;
  @Input() selectedItems: PhysicianModel[] = [];
  @Input() removable: boolean = false;
  @Input() showClearIcon: boolean = false;
  @Input() showOpenInNewIcon: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() invalid: boolean = false;
  @Input() custody: UserModel = new UserModel();
  @Input() representative: UserModel = new UserModel();
  @Input() procedure: ProcedureModel = new ProcedureModel();
  @Input() tooltip: TranslationsKeys;
  @Input() autoScrollOnFocus: boolean = false;
  @Input() showInvalidStateOnInit: boolean = false;

  @Output() assignPhysicianEmitter = new EventEmitter<{ custodyId: string; physicianId: string }>();
  @Output() selectedOptionEmitter = new EventEmitter<PhysicianModel>();
  @Output() removeChipEmitter = new EventEmitter<string>();
  @Output() clearInputEmitter = new EventEmitter<void>();
  @Output() valueChangedEmitter = new EventEmitter<string>();

  permissionService = inject(PermissionService);
  private physiciansService = inject(PhysiciansService);
  private dialog = inject(MatDialog);

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  searchedData$: BehaviorSubject<PhysicianModel[]> = new BehaviorSubject<PhysicianModel[]>([]);
  forceBlurEvent: boolean = false;

  readonly displayFn = DisplayFullName;

  searchPhysicians(value: string, skipSelectSingleOption: boolean = false, page: number = 0): void {
    this.initialLoading$.next(false);
    this.forceBlurEvent = false;

    if (this.loading$.value) {
      return;
    }

    this.loading$.next(true);
    this.searchedData$.next([]);

    const params: IFilter = {
      fullName: value,
      size: DefaultAutoCompleteSize,
      state: 'ACTIVE',
      page,
      sort: 'fullName,asc'
    };

    if (!this.permissionService.isRole('SALES')) {
      params.representativeId = this.representative?.id;
    }

    if (this.custody?.role === 'SALES' || this.custody?.role === 'DISTRIBUTOR') {
      params.custodyId = this.custody.id;
    }

    if (UsersService.getUser().organization.showProcedureAssignedToPhysician && this.procedure?.id) {
      params.procedureId = this.procedure.id;
    }

    const queryParams: PhysiciansPageableParams = new PhysiciansPageableParams(params);

    if (
      this.permissionService.isRole('ADMIN') ||
      this.permissionService.isRole('DISTRIBUTOR') ||
      this.permissionService.isRole('REGIONAL_MANAGER')
    ) {
      this.physiciansService
        .getPageableByRepresentative(queryParams)
        .pipe(
          take(1),
          finalize(() => this.loading$.next(false))
        )
        .subscribe(data => {
          if (this.selectedItems.length) {
            this.searchedData$.next(FilterUniqueArray(data.content, this.selectedItems));
          } else {
            this.searchedData$.next(data.content);
          }

          this.selectSingleOption(value, skipSelectSingleOption);
          //CC-5432 Load next page if all items selected
          if (this.searchedData$.value?.length === 0 && data?.totalElements > this.selectedItems?.length && page < data.totalPages) {
            setTimeout(() => this.searchPhysicians(value, skipSelectSingleOption, page + 1));
          }
        });
    } else {
      this.physiciansService
        .getPageable(queryParams)
        .pipe(
          take(1),
          finalize(() => this.loading$.next(false))
        )
        .subscribe(data => {
          if (this.selectedItems.length) {
            this.searchedData$.next(FilterUniqueArray(data.content, this.selectedItems));
          } else {
            this.searchedData$.next(data.content);
          }

          this.selectSingleOption(value, skipSelectSingleOption);
          //CC-5432 Load next page if all items selected
          if (this.searchedData$.value?.length === 0 && data?.totalElements > this.selectedItems?.length && page < data.totalPages) {
            setTimeout(() => this.searchPhysicians(value, skipSelectSingleOption, page + 1));
          }
        });
    }
  }

  doIconAction(icon: TIcons): void {
    if (icon === 'close') {
      this.clearInputEmitter.emit();
      this.searchPhysicians('', true);
    }
  }

  async createPhysician(): Promise<void> {
    await import('../../../../../features/directory/physicians/physicians.module');
    const dialogConfig: MatDialogConfig = { ...CreateMediumEntityConfig };

    dialogConfig.data = false;

    const dialogRef = this.dialog.open(CreatePhysicianComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(id => {
        if (!id) {
          return;
        }

        this.physiciansService
          .getPhysician(id)
          .pipe(take(1))
          .subscribe(physician => {
            this.selectedOptionEmitter.emit(physician);
          });

        if (
          this.permissionService.isRole('ADMIN') &&
          (this.representative?.role === 'SALES' || this.representative?.role === 'DISTRIBUTOR')
        ) {
          this.assignPhysicians(this.representative.id, id);
        }
      });
  }

  assignPhysicians(custodyId: string, physicianId: string): void {
    this.assignPhysicianEmitter.emit({ custodyId, physicianId });
  }

  selectOption(physician: PhysicianModel): void {
    this.selectedOptionEmitter.emit(physician);
    if (this.multiple) {
      //Hide selected item from search results
      this.searchedData$.next(this.searchedData$.value.filter(c => c.id !== physician.id));
    }
  }

  removeChip(physicianId: string): void {
    this.removeChipEmitter.emit(physicianId);
  }

  private selectSingleOption(value: string, skipSelectSingleOption: boolean = false): void {
    if (skipSelectSingleOption && this.searchedData$.value.length === 1) {
      this.forceBlurEvent = true;
      return;
    }

    if (!value && this.searchedData$.value.length === 1 && this.required) {
      this.selectOption(this.searchedData$.value[0]);
      this.forceBlurEvent = true;
    } else {
      this.forceBlurEvent = false;
    }
  }
}
